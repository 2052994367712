import {createGlobalStyle} from 'styled-components'

import breakpoints from './constants/breakpoints'
import Colours from './constants/colours'

const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${Colours.background};
    background-size: 180px 180px;
    font-size: 12px;
    overflow: hidden;

    @media (min-width: ${breakpoints.fromSmall}px) {
      font-size: 14px;
    }

    @media (min-width: ${breakpoints.fromMedium}px) {
      font-size: 16px;
    }
  }

  body {
    background: none;
    margin: 0px;
    padding: 0px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
  }

  h1, h2, h3 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
  }
`

export default GlobalStyle
