// Palette: https://coolors.co/ff7500-05a8aa-ffffff-06070e-605856

enum Colours {
  light = '#DBDBF5',
  dark = '#18395C',
  accent = '#3f9250',
  primary = '#2292A3',
  secondary = '#328C84',
  white = '#ffffff',
  black = '#06070e',
  grey = '#232323',
  background = '#245d7a'
}

export default Colours
